.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeUp 0.4s ease-in-out forwards;
  background-color: rgb(0 0 0 / 63%);
  opacity: 1;
  z-index: 2 !important;
}

@keyframes fadeUp {
  0% {
    transform: perspective(0px) rotateX(0);
    opacity: 0;
  }

  100% {
    transform: perspective(0px) rotateY(0);
    opacity: 1;
  }
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0006;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
}

.custom-modal .Congratulation-wrapper {
  max-width: 770px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  transform: translateY(-50px);
  opacity: 1;
  transition: all .2s ease-in-out;
}

.custom-modal .Congratulation-wrapper.active {
  animation: active 0.3s forwards;
  animation-duration: 1s;
}

@keyframes active {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* ------------------------------End model cross button css */
.cross-icon {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.privacy-policy-main {
  height: 40vh;
  overflow: auto;
  padding: 0px 15px 0px 5px;
  text-align: justify;
}

/* Waitlist css start */

.waitlist-body {
  background: url("../public/assets/images/hero-banner.png"), url("../public/assets/images/banner-layer-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.new-header-blk .newlogo-blk {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 50px;
  padding: 30px 0px 10px;
  cursor: pointer;
}

span.backicon {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

a.backbtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #000;
  width: 76px;
}

a.logobtn {
  width: 220px;
}

.new-layout .form-header p {
  width: 100%;
}

.form-heading h2 {
  font-size: 20px !important;
  font-weight: 600;
  font-family: var(--secondary-font);
  color: var(--primary-clr);
  margin-bottom: 0px;
}

.new-layout .common-wrapper {
  position: relative;
  /* background: #ffffff4d; */
  background: transparent;
}

/* .new-layout .common-wrapper::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: url("../public/assets/images/lumakin-lg-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0.2;
} */

@media screen and (max-width:576px) {
  .new-header-blk .newlogo-blk {
    gap: 20px;
  }

  a.logobtn {
    width: 125px;
  }
}

/* .modal-content {
  background: #1a1d34 !important;
  color: white !important;
} */

/* .modal-content2 {
  background: white !important;
  color: white !important;
} */